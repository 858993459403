import Head from "next/head";
import { PropsWithChildren } from "react";
import { Content } from "../components/marketing/content";
import { Layout } from "../components/marketing/layout";

export default function ErrorLayout({
  children,
  className,
  title = "An error occured",
  isApp,
  showRedirect = true,
}: PropsWithChildren<{
  title?: string;
  className?: string;
  isApp?: boolean;
  showRedirect?: boolean;
}>) {
  return (
    <Layout>
      <Head>
        <title>{`${title} - R8ER`}</title>
        <link rel="icon" href="/favicon.png" />
      </Head>
      <Content isFullWidth isCentered isLightGrey>
        {isApp ? (
          children
        ) : (
          <>
            <h1
              style={{
                color: "var(--brand-secondary)",
              }}
            >
              404 - Page Not Found
            </h1>
            <p>The page you requested has been moved or never existed.</p>
          </>
        )}
      </Content>
    </Layout>
  );
}
