import { PropsWithChildren } from "react";
import { Container } from "../container";
import classes from "./content.module.css";

interface ContentProps {
  isFullWidth?: boolean;
  isRightAligned?: boolean;
  isGrey?: boolean;
  isLightGrey?: boolean;
  isGreen?: boolean;
  isBlue?: boolean;
  isCentered?: boolean;
  isNarrow?: boolean;
  backgroundImage?: string;
  innerImage?: string;
  className?: string;
  outerImage?: string;
  minHeight?: number;
  style?: any;
  containerWidth?: number;
}

const Content = ({
  isFullWidth,
  isRightAligned,
  isGrey,
  isGreen,
  isLightGrey,
  isBlue,
  isCentered,
  isNarrow,
  backgroundImage,
  innerImage,
  outerImage,
  minHeight,
  children,
  style,
  containerWidth,
  className,
}: PropsWithChildren<ContentProps>) => {
  const sectionClasses = [classes.marketing__content];
  if (isFullWidth) sectionClasses.push(classes.marketing__content_fullwidth);
  if (isGrey) sectionClasses.push(classes.marketing__content_grey);
  if (isGreen) sectionClasses.push(classes.marketing__content_green);
  if (isLightGrey) sectionClasses.push(classes.marketing__content_lightgrey);
  if (isBlue) sectionClasses.push(classes.marketing__content_blue);
  if (isCentered) sectionClasses.push(classes.marketing__content_centered);
  if (isNarrow) sectionClasses.push(classes.marketing__content_narrow);
  if (isRightAligned) sectionClasses.push(classes.marketing__content_right);
  if (minHeight) sectionClasses.push(classes.marketing__content_hasminheight);
  if (className) sectionClasses.push(className);

  const sectionStyles = {};
  if (minHeight) {
    sectionStyles["minHeight"] = `${minHeight}px`;
  }

  const allStyles = Object.assign(sectionStyles, style);

  return (
    <section className={sectionClasses.join(" ")} style={allStyles}>
      <Container width={containerWidth}>
        <div className={classes.marketing__content_columns}>
          <div>
            <div className={classes.marketing__content_column_content}>
              {children}
            </div>
          </div>
          <div>
            {innerImage && (
              <img
                className={classes.marketing__content_innerimage}
                src={innerImage}
              />
            )}
          </div>
        </div>
      </Container>

      {backgroundImage && (
        <div
          className={classes.marketing__content_backgroundimage}
          style={{ backgroundImage: `url(${backgroundImage})` }}
        ></div>
      )}

      {outerImage && (
        <img
          className={classes.marketing__content_outerimage}
          src={outerImage}
        />
      )}
    </section>
  );
};

export { Content };
