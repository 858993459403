import { PropsWithChildren } from "react";
import classes from "./columns.module.css";

interface ColumnsProps {
  count?: number;
  columnStructure?: string;
  style?: object;
  classList?: Array<string>;
}

interface Columnprops {
  style?: any;
  classList?: Array<string>;
  removeInner?: boolean;
}

const Column = ({
  children,
  style,
  classList,
  removeInner,
}: PropsWithChildren<Columnprops>) => {
  classList = classList || [];
  if (removeInner) return <>{children}</>;
  let allClasses = [classes.marketing__columns_inner, ...classList];
  return (
    <div style={style} className={allClasses.join(" ")}>
      {children}
    </div>
  );
};

const Columns = ({
  count,
  columnStructure,
  style,
  children,
  classList,
}: PropsWithChildren<ColumnsProps>) => {
  let cssClassList = [classes.marketing__columns];
  if (classList) cssClassList = [...cssClassList, ...classList];

  // Default to 1 column.
  if (!count) count = 1;

  // Create a column structure based on the number of columns.
  let columnStructureString = new Array(count).fill("1fr").join(" ");

  // Allow overriding the column structure with a custom value.
  if (columnStructure) {
    columnStructureString = columnStructure;
  }

  const styles = Object.assign(style ?? {}, {
    gridTemplateColumns: columnStructureString,
  });

  return (
    <div className={cssClassList.join(" ")} style={styles}>
      {children}
    </div>
  );
};

export { Columns, Column };
