import Head from "next/head";
import NextNprogress from "nextjs-progressbar";
import { PropsWithChildren } from "react";
import { ConsentBanner } from "react-hook-consent";
import "react-hook-consent/dist/styles/style.css";
import Footer from "../footer";
import Header from "../header";
import classes from "./layout.module.css";

interface LayoutProps {}

const Layout = ({ children }: PropsWithChildren<LayoutProps>) => (
  <div>
    <NextNprogress
      color="#002949"
      startPosition={0.3}
      stopDelayMs={200}
      height={5}
      showOnShallow={true}
    />
    <Head>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GA_ID}`}
      ></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.GA_ID}');
        `,
        }}
      ></script>
    </Head>
    <ConsentBanner
      settings={{
        label: "Tailor Preferences",
        modal: { title: "Tailor Cookie Preferences" },
      }}
      decline={{ label: "Decline All" }}
      approve={{ label: "Approve All" }}
    >
      <>
        This website uses cookies for analytics and efficiency, however, some
        cookies are essential. Select "Accept All" to accept all cookies,
        "Decline All" for only essential cookies, or “Tailor Preferences” to
        select which specific cookies you accept. See our{" "}
        <a
          href="/cookies"
          style={{ textDecoration: "underline", fontWeight: "bold" }}
        >
          Cookie Policy
        </a>{" "}
        for more information
      </>
    </ConsentBanner>
    <Header />
    <div className={classes.marketing__layout}>{children}</div>
    <Footer />
  </div>
);

export { Layout };
