import { AppType } from "next/dist/shared/lib/utils";
import { useRouter } from "next/router";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { ConsentProvider, useConsent } from "react-hook-consent";
import "../styles/globals.css";
import Error from "./_error";

const sendPageviewHit = (url) =>
  window.gtag?.("config", process.env.GOOGLE_ANALYTICS_ID, { page_path: url });

export const LoaderContext = createContext({
  isLoading: false,
  setIsLoading: (val: boolean) => {},
});

const MyApp = ({ children }: PropsWithChildren<{}>) => {
  const router = useRouter();

  const [minimumTime, setMinimumTime] = useState(500);
  const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(true);
  const [loading, setLoading] = useState(false);

  const restartTimeout = useCallback(() => {
    setMinimumTimeElapsed(false);
    setTimeout(() => {
      setMinimumTimeElapsed(true);
    }, minimumTime);

    //simulate random load time between 0 and 5 seconds
    const randomLoadTime = 4000;
    setLoading(true);
    //simulate loading
    setTimeout(() => {
      setLoading(false);
    }, randomLoadTime);
  }, [setMinimumTimeElapsed, setLoading, minimumTime]);

  const { hasConsent } = useConsent();

  const trackAnalytics = hasConsent("analytics");
  useEffect(() => {
    const handleRouteChange = (url) =>
      trackAnalytics ? sendPageviewHit(url) : null;

    router.events.on("routeChangeComplete", handleRouteChange);

    router.events.on("routeChangeStart", () => setLoading(true));
    router.events.on("routeChangeComplete", () => setLoading(false));
    router.events.on("routeChangeError", () => setLoading(false));

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
      scriptProps={{
        async: false,
        defer: false,
        appendTo: "head",
        nonce: undefined,
      }}
    >
      <LoaderContext.Provider
        value={{
          isLoading: loading,
          setIsLoading: (val: boolean) => setLoading(val),
        }}
      >
        {children}
      </LoaderContext.Provider>
    </GoogleReCaptchaProvider>
  );
};

const ConsentedApp: AppType = ({ Component, pageProps }) => {
  // @ts-ignore
  if (pageProps.error) console.error(pageProps.error);
  // @ts-ignore
  if (pageProps.error) return <Error error={pageProps.error} />;

  return (
    <ConsentProvider
      options={{
        services: [
          {
            id: "necessary",
            name: "Necessary",
            cookies: [
              {
                pattern: "sessionId",
              },
              {
                pattern: "sessionAuthenticated",
              },
            ],
            mandatory: true,
          },
          {
            id: "functional",
            name: "Functional",
            mandatory: false,
          },
          {
            id: "performance",
            name: "Performance",
            mandatory: false,
          },
          {
            id: "analytics",
            name: "Analytics",
            cookies: [
              {
                pattern: /_ga.*/,
              },
              {
                pattern: "AEC",
              },
              { pattern: "__gsas" },
              { pattern: "__gpi" },
              { pattern: "__gpi_optout" },
              { pattern: "NID" },
              { pattern: "DSID" },
              { pattern: "test_cookie" },
              { pattern: "id" },
              { pattern: "__gads" },
              { pattern: "GED_PLAYLIST_ACTIVITY" },
              { pattern: "ACLK_DATA" },
              { pattern: "pm_sess" },
              { pattern: "pm_sess_NNN" },
              { pattern: "aboutads_sessNNN" },
              { pattern: "FPAU" },
              { pattern: "ANID" },
              { pattern: "AID" },
              { pattern: "IDE" },
              { pattern: "TAID" },
              { pattern: "FPGCLDC" },
              { pattern: "_gcl_dc" },
              { pattern: "_gcl_au" },
              { pattern: "FLC" },
              { pattern: "RUL" },
              { pattern: "FCCDCF" },
              { pattern: "FCNEC" },
              { pattern: "FPGCLAW" },
              { pattern: "FPGCLGB" },
              { pattern: "_gcl_gb" },
              { pattern: /_gac_gb_.*/ },
              { pattern: "_gcl_aw" },
              { pattern: "1P_JAR" },
              { pattern: "Conversion" },
              { pattern: "YSC" },
              { pattern: "VISITOR_INFO1_LIVE" },
              { pattern: "VISITOR_INFO1_LIVE__k" },
              { pattern: "VISITOR_INFO1_LIVE__default" },
              { pattern: "FPLC" },
              { pattern: "_ga" },
              { pattern: /_gac_.*/ },
              { pattern: "_gid" },
              { pattern: /_gat.*/ },
              { pattern: "__utma" },
              { pattern: "__utmb" },
              { pattern: "__utmc" },
              { pattern: "__utmt" },
              { pattern: "__utmz" },
              { pattern: "__utmv" },
              { pattern: "AMP_TOKEN" },
              { pattern: "FPID" },
              { pattern: "GA_OPT_OUT" },
              { pattern: "_ga_.*" },
              { pattern: /_dc_gtm_.*/ },
              { pattern: "_gaexp" },
              { pattern: "_gaexp_rc" },
              { pattern: "_opt_awcid" },
              { pattern: "_opt_awmid" },
              { pattern: "_opt_awgid" },
              { pattern: "_opt_awkid" },
              { pattern: "_opt_utmc" },
              { pattern: "_gcl_gf" },
              { pattern: "_gcl_ha" },
              { pattern: "PAIDCONTENT" },
              { pattern: "_opt_expid" },
            ],
            mandatory: false,
          },
          {
            id: "advertisement",
            name: "Advertisement",
            mandatory: false,
          },
          {
            id: "others",
            name: "Others",
            mandatory: false,
          },
        ],
        theme: "dark",
      }}
    >
      <MyApp>
        <Component {...pageProps} />
      </MyApp>
    </ConsentProvider>
  );
};

export default ConsentedApp;
