import ErrorLayout from "../layout/error";
import { AppError } from "../lib/error";

interface ErrorProps {
  error: AppError;
}

const Error = ({ error }: ErrorProps) => (
  <ErrorLayout title="An error occurred" isApp={true}>
    <span>
      {process.env.DEBUG_ERRORS ? error?.message : "Internal server error"}
    </span>
    <br />
    <br />
    <span>
      Please try again or contact us with the following reference: <br />
      {error?.id}
    </span>
  </ErrorLayout>
);

export default Error;
