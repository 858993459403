import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import { FiUser } from "react-icons/fi";
import { Container } from "../container";
import { IconLogin, IconSignup } from "../icons";
import classes from "./header.module.css";

const Header = () => {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [navClasses, setNavClasses] = useState([classes.marketing__header_nav]);
  const [navActive, setNavActive] = useState(false);
  const router = useRouter();
  const toggleNav = () => {
    setNavActive(!navActive);
  };

  useEffect(() => {
    router.events.on("routeChangeStart", () => setNavActive(false));
  }, [router.events]);

  const evalActive = () => {
    if (typeof document !== "undefined") {
      document
        .querySelector("body")
        .classList[navActive ? "add" : "remove"]("scroll-locked");

      setNavClasses(
        navActive
          ? [
              classes.marketing__header_nav,
              classes.marketing__header_nav_active,
            ]
          : [classes.marketing__header_nav]
      );
    }
  };

  useEffect(() => {
    if (typeof document !== "undefined" && document.cookie) {
      const cookies = new Cookies(document.cookie);
      if (cookies.get("sessionAuthenticated") === "yes") setAuthenticated(true);
    }
  }, []);
  useEffect(() => {
    evalActive();
  }, [navActive]);

  return (
    <header className={classes.marketing__header}>
      <Container width={100}>
        <Link href="/">
          <img
            src="/logo-mark-white.svg"
            className={classes.marketing__header_logo}
          />
        </Link>

        <span
          className={classes.marketing__header_nav_icon}
          onClick={() => toggleNav()}
        >
          <svg height="32" width="32" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="#fff"
              d="M4 10h24a2 2 0 000-4H4a2 2 0 000 4zm24 4H4a2 2 0 000 4h24a2 2 0 000-4zm0 8H4a2 2 0 000 4h24a2 2 0 000-4z"
            />
          </svg>
        </span>

        <nav className={navClasses.join(" ")}>
          <Link
            href="/investors"
            className={classes.marketing__header_nav_item}
          >
            Investor
          </Link>
          <Link
            href="/entrepreneurs"
            className={classes.marketing__header_nav_item}
          >
            Entrepreneur
          </Link>
          <Link
            href="/categories"
            className={classes.marketing__header_nav_item}
          >
            Categories
          </Link>
          {isAuthenticated ? (
            <Link
              href="/app"
              className={[
                classes.marketing__header_nav_item,
                classes.marketing__header_nav_item_blue,
              ].join(" ")}
            >
              <FiUser style={{ height: "30px" }} />
              Back to the app
            </Link>
          ) : (
            <>
              <Link
                href="/auth/signup"
                className={[
                  classes.marketing__header_nav_item,
                  classes.marketing__header_nav_item_blue,
                ].join(" ")}
              >
                <IconSignup />
                Sign Up
              </Link>
              <Link
                href="/auth/signin"
                className={[
                  classes.marketing__header_nav_item,
                  classes.marketing__header_nav_item_blue,
                ].join(" ")}
              >
                <IconLogin />
                Login
              </Link>
            </>
          )}
        </nav>
      </Container>
    </header>
  );
};

export default Header;
