import { PropsWithChildren } from "react";
import classes from "./container.module.css";

interface ContainerProps {
  isNarrow?: boolean;
  width?: number;
}

const Container = ({
  children,
  isNarrow,
  width,
}: PropsWithChildren<ContainerProps>) => {
  const classList = [classes.layout__container];
  if (isNarrow) classList.push(classes.layout__container_narrow);
  if (width) classList.push(classes[`layout__container_${width}`]);

  return <div className={classList.join(" ")}>{children}</div>;
};

export { Container };
