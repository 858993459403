import classes from "./icons.module.css";

interface IconProps {
  className?: string;
}

const IconLogoMarkWhite = ({ className }: IconProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="84"
    height="85"
  >
    <g transform="translate(-925 -1951)">
      <ellipse
        cx="42"
        cy="42.5"
        fill="#f7f7f7"
        rx="42"
        ry="42.5"
        transform="translate(925 1951)"
      />
      <g>
        <path
          fill="#00325a"
          d="M382.8,235.984a15.237,15.237,0,0,0-22.827,20.1l2.723-2.722a11.428,11.428,0,1,1,17.41,1.489l-8.085,8.085-4.167-4.168-2.695,2.694,6.862,6.862L382.8,257.542a15.261,15.261,0,0,0,0-21.558"
          data-name="Path 69"
          transform="translate(0 -161) translate(594.982 1893.797)"
        />
        <path
          fill="#00ab9d"
          d="M382.513,271.615l-.814-.814L379,273.494l.814.814a14.529,14.529,0,1,1-20.547,0l10.274-10.273,4.177,4.177,2.694-2.694-6.871-6.872-12.968,12.968a18.339,18.339,0,1,0,25.935,0"
          transform="translate(0 -161) translate(597.455 1881.764)"
        />
      </g>
    </g>
  </svg>
);

const IconLogoMarkLight = ({ className }: IconProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="84"
    height="85"
  >
    <g data-name="Group 48" transform="translate(-925 -1951)">
      <ellipse
        data-name="Ellipse 37"
        cx="42"
        cy="42.5"
        rx="42"
        ry="42.5"
        transform="translate(925 1951)"
        fill="#00ab9d"
      />
      <g data-name="Group 47">
        <path
          data-name="Path 69"
          d="M977.782 1968.781a15.237 15.237 0 00-22.827 20.1l2.723-2.722a11.428 11.428 0 1117.41 1.489l-8.085 8.085-4.167-4.168-2.695 2.694 6.862 6.862 10.779-10.782a15.261 15.261 0 000-21.558"
          fill="#00325a"
        />
        <path
          data-name="Path 70"
          d="M979.968 1992.379l-.814-.814-2.699 2.693.814.814a14.529 14.529 0 11-20.547 0l10.274-10.273 4.177 4.177 2.694-2.694-6.871-6.872-12.968 12.968a18.339 18.339 0 1025.935 0"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

const IconLogoMarkLightSmall = ({ className }: IconProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="43"
  >
    <g transform="translate(-925.247 -1951)">
      <ellipse
        cx="21"
        cy="21.5"
        fill="#00ab9d"
        rx="21"
        ry="21.5"
        transform="translate(925.247 1951)"
      />
      <g>
        <path
          fill="#00325a"
          d="M369.946,233.782A7.708,7.708,0,0,0,358.4,243.948l1.377-1.377a5.781,5.781,0,1,1,8.807.753l-4.09,4.09-2.108-2.109-1.363,1.363,3.472,3.472,5.453-5.453a7.72,7.72,0,0,0,0-10.906"
          data-name="Path 69"
          transform="translate(936.974 1957.74) translate(-355.22 -231.527)"
        />
        <path
          fill="#fff"
          d="M367.048,265.207l-.412-.412-1.363,1.363.412.412a7.35,7.35,0,1,1-10.394,0l5.2-5.2,2.113,2.113,1.363-1.363-3.476-3.476-6.56,6.56a9.277,9.277,0,1,0,13.12,0"
          transform="translate(936.974 1957.74) translate(-351.215 -251.014)"
        />
      </g>
    </g>
  </svg>
);

const IconLogoMarkDark = ({ className }: IconProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="84"
    height="85"
  >
    <g data-name="Group 49" transform="translate(-925 -1951)">
      <ellipse
        data-name="Ellipse 37"
        cx="42"
        cy="42.5"
        rx="42"
        ry="42.5"
        transform="translate(925 1951)"
        fill="#00325a"
      />
      <g data-name="Group 47">
        <path
          data-name="Path 69"
          d="M977.782 1968.781a15.237 15.237 0 00-22.827 20.1l2.723-2.722a11.428 11.428 0 1117.41 1.489l-8.085 8.085-4.167-4.168-2.695 2.694 6.862 6.862 10.779-10.782a15.261 15.261 0 000-21.558"
          fill="#fff"
        />
        <path
          data-name="Path 70"
          d="M979.968 1992.379l-.814-.814-2.699 2.693.814.814a14.529 14.529 0 11-20.547 0l10.274-10.273 4.177 4.177 2.694-2.694-6.871-6.872-12.968 12.968a18.339 18.339 0 1025.935 0"
          fill="#00ab9d"
        />
      </g>
    </g>
  </svg>
);

const IconSignup = ({ className }: IconProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30.4"
    height="30.4"
  >
    <path fill="#fff" fillRule="evenodd" d="M15 8a5 5 0 11-4 4 5 5 0 014-4z" />
    <path
      fill="#fff"
      d="M15 0A15 15 0 110 15 15 15 0 0115 0zm0 2a13 13 0 100 26 13 13 0 000-26z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M12 18h7c3 0 5 3 5 6a12 12 0 01-17 0c0-3 1-6 5-6z"
    />
  </svg>
);

const IconLogin = ({ className }: IconProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30.4"
    height="30.6"
  >
    <g fill="#fff">
      <path d="M6 25A13 13 0 106 6L5 4a15 15 0 110 22z" />
      <path d="M19 15l-5-5-1 2 2 2H0v2h15l-2 3 1 1 5-5z" />
    </g>
  </svg>
);

const IconSocialLinkedIn = ({ className }: IconProps) => (
  <a href="https://www.linkedin.com/company/r8erhealth" target="_blank">
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="49"
      height="49"
    >
      <path
        fill="#00325a"
        d="M24.5,0A24.5,24.5,0,1,1,0,24.5,24.5,24.5,0,0,1,24.5,0Z"
      />
      <g fill="#fff" transform="translate(-169.393 -135.062)">
        <path d="M201.583,172.424a.851.851,0,0,0,.851.852h4.231a.851.851,0,0,0,.851-.852v-8c0-3.478.039-9.806-6.735-9.806-3.059,0-4.5,1.12-5.463,2.57v-1.441a.851.851,0,0,0-.851-.852h-4.092a.851.851,0,0,0-.851.852v16.675a.851.851,0,0,0,.851.852h4.092a.851.851,0,0,0,.851-.852v-8.941c.106-1.2.635-3.878,3.243-3.878,3.361,0,3.022,3.911,3.022,5.067Z" />
        <rect
          width="5.8"
          height="18.4"
          rx=".9"
          transform="translate(181.118 154.897)"
        />
        <path d="M184.016,145.849a3.745,3.745,0,1,0,3.745,3.745A3.744,3.744,0,0,0,184.016,145.849Z" />
      </g>
    </svg>
  </a>
);

const IconSocialInstagram = ({ className }: IconProps) => (
  <a href="https://www.instagram.com/R8ERHealth/" target="_blank">
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="49"
      height="49"
    >
      <path
        fill="#00325a"
        d="M24.5,0A24.5,24.5,0,1,1,0,24.5,24.5,24.5,0,0,1,24.5,0Z"
      />
      <path
        fill="#fff"
        d="M257.686,145.849H246.6a8.179,8.179,0,0,0-8.17,8.17V165.1a8.18,8.18,0,0,0,8.17,8.171h11.086a8.18,8.18,0,0,0,8.171-8.171V154.019A8.179,8.179,0,0,0,257.686,145.849ZM263.1,165.1a5.412,5.412,0,0,1-5.412,5.412H246.6a5.412,5.412,0,0,1-5.411-5.412V154.019a5.412,5.412,0,0,1,5.411-5.411h11.086a5.412,5.412,0,0,1,5.412,5.411V165.1Z"
        transform="translate(-227.643 -135.062)"
      />
      <path
        fill="#fff"
        d="M252.143,152.469a7.094,7.094,0,1,0,7.094,7.093A7.1,7.1,0,0,0,252.143,152.469Zm0,11.428a4.335,4.335,0,1,1,4.335-4.335A4.334,4.334,0,0,1,252.143,163.9Z"
        transform="translate(-227.643 -135.062)"
      />
      <circle
        cx="1.7"
        cy="1.7"
        r="1.7"
        fill="#fff"
        transform="translate(29.908 15.76)"
      />
    </svg>
  </a>
);

const IconSocialFacebook = ({ className }: IconProps) => (
  <a href="http://facebook.com/R8ERHealth" target="_blank">
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="49"
      height="49"
    >
      <path
        fill="#00325a"
        d="M24.5,0A24.5,24.5,0,1,1,0,24.5,24.5,24.5,0,0,1,24.5,0Z"
      />
      <path
        fill="#fff"
        d="M301.981,161.484h3.09l-.02,11.792h5.187l.02-11.792h4.108a.729.729,0,0,0,.724-.643l.454-3.834a.729.729,0,0,0-.724-.815h-4.582v-3.207c0-1.454.4-2.445,2.489-2.445h1.931a.729.729,0,0,0,.729-.729v-3.232a.729.729,0,0,0-.729-.729h-3.147c-3.835,0-6.46,2.34-6.46,6.639v3.7h-3.085a.73.73,0,0,0-.729.732l.015,3.834A.73.73,0,0,0,301.981,161.484Z"
        transform="translate(-283.893 -135.062)"
      />
    </svg>
  </a>
);

const IconSocialTwitter = ({ className }: IconProps) => (
  <a href="https://twitter.com/R8ERHealth" target="_blank">
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="49"
      height="49"
      viewBox="0 0 131.593 131.593"
    >
      <path
        id="Path_137"
        d="M65.8,0A65.8,65.8,0,1,1,0,65.8,65.8,65.8,0,0,1,65.8,0Z"
        fill="#00325a"
      ></path>
      <path
        id="Path_138"
        d="M288.074,249.195c29.928,0,46.3-24.8,46.3-46.3q0-1.055-.048-2.1a33.08,33.08,0,0,0,8.118-8.425,32.546,32.546,0,0,1-9.346,2.562,16.33,16.33,0,0,0,7.157-9,32.625,32.625,0,0,1-10.331,3.95,16.283,16.283,0,0,0-27.729,14.84,46.212,46.212,0,0,1-33.54-17,16.286,16.286,0,0,0,5.038,21.721,16.165,16.165,0,0,1-7.369-2.036c0,.067,0,.137,0,.207a16.277,16.277,0,0,0,13.052,15.952,16.266,16.266,0,0,1-7.348.279,16.288,16.288,0,0,0,15.2,11.3,32.649,32.649,0,0,1-20.209,6.964,33.061,33.061,0,0,1-3.881-.226,46.046,46.046,0,0,0,24.941,7.31"
        transform="translate(-234.525 -149.829)"
        fill="#fff"
      ></path>
    </svg>
  </a>
);

const SocialIcons = () => (
  <div className={classes.icons__socialicons}>
    <IconSocialLinkedIn />
    <IconSocialInstagram />
    <IconSocialTwitter />
  </div>
);

export {
  IconLogoMarkWhite,
  IconLogoMarkLight,
  IconLogoMarkLightSmall,
  IconLogoMarkDark,
  IconSignup,
  IconLogin,
  IconSocialLinkedIn,
  IconSocialInstagram,
  IconSocialFacebook,
  IconSocialTwitter,
  SocialIcons,
};
